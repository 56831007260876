import React, { useEffect, useState } from "react"
import { navigate } from "gatsby"
import { renderRichText } from "gatsby-source-contentful/rich-text"

import Button from "components/Button"
import { Container } from "components/Layout/styled"
import {
  AboutUs,
  AboutUsHeroSubtitle,
  AboutUsHeroTitle,
  BelieveSection,
  BelieveSectionContent,
  BelieveSectionTitle,
  Card,
  CardContainer,
  CardImage,
  CardsSection,
  HeroSection,
  QuestionSection,
  QuestionTitle,
  ValueText,
  ValueTitle,
} from "./styled"

const defaultStyles = {
  backgroundColor: "#11100f",
  color: "#fff",
  variant: "dark",
}

const activeStyles = {
  backgroundColor: "#fff",
  color: "#333",
  variant: "white",
}

const AboutPage = ({ data: { sections } }) => {
  const sectionData = sections[0]
  const {
    heroTitle,
    heroSubtitle,
    believeInTitle,
    believeInSubtitle,
    valuesSection,
    questionToAction,
    btnText,
  } = sectionData

  const [pageStyles, setPageStyles] = useState(defaultStyles)
  const { backgroundColor, color, variant } = pageStyles

  const changeStyles = () => {
    window.scrollY >= 240
      ? setPageStyles(activeStyles)
      : setPageStyles(defaultStyles)
  }

  useEffect(() => {
    changeStyles()
    window.addEventListener("scroll", changeStyles)

    return () => {
      window.removeEventListener("scroll", changeStyles)
    }
  }, [])

  const handleContact = () => {
    navigate("/contact-us")
  }
  return (
    <AboutUs backgroundColor={backgroundColor} color={color}>
      <Container>
        <HeroSection>
          <AboutUsHeroSubtitle>{heroSubtitle}</AboutUsHeroSubtitle>
          <AboutUsHeroTitle>{renderRichText(heroTitle)}</AboutUsHeroTitle>
        </HeroSection>
        <BelieveSection>
          <BelieveSectionTitle>{believeInTitle}</BelieveSectionTitle>
          <BelieveSectionContent>
            {renderRichText(believeInSubtitle)}
          </BelieveSectionContent>
        </BelieveSection>
        <CardsSection>
          {valuesSection.map(
            ({ title, image: { file }, text: { text } }, index) => (
              <Card key={index} backgroundColor={title}>
                <CardImage src={file.url} alt={text} />
                <CardContainer>
                  <ValueText>{`value${index + 1}`}</ValueText>
                  <ValueTitle>{text}</ValueTitle>
                </CardContainer>
              </Card>
            )
          )}
        </CardsSection>
        <QuestionSection>
          <QuestionTitle>{renderRichText(questionToAction)}</QuestionTitle>
          <Button onClick={handleContact} variant={variant}>
            {btnText}
          </Button>
        </QuestionSection>
      </Container>
    </AboutUs>
  )
}

export default AboutPage
