import React from "react"
import { graphql } from "gatsby"

import AboutPage from "../containers/AboutPage"

import { Seo } from "components"

const AboutUsPage = ({ data: { allContentfulPage } }) => {
  const pageData = allContentfulPage.nodes[0]
  return (
    <>
      <AboutPage data={pageData} />
    </>
  )
}

export const Head = ({ location }) => (
  <Seo
    title="About us"
    description="Let's work together to build something great"
    ogUrl={location.pathname}
  />
)

export const query = graphql`
  query {
    allContentfulPage(filter: { name: { eq: "About Us" } }) {
      nodes {
        id
        name
        sections {
          ... on ContentfulAboutUsSections {
            id
            believeInSubtitle {
              raw
            }
            believeInTitle
            heroSubtitle
            heroTitle {
              raw
            }
            questionToAction {
              raw
            }
            btnText
            valuesSection {
              title
              image {
                file {
                  url
                }
              }
              text {
                text
              }
            }
          }
        }
      }
    }
  }
`

export default AboutUsPage
