import styled from "styled-components"

export const AboutUs = styled.div`
  padding: calc(90px + 8vh) 0 8vh 0;
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: ${({ backgroundColor }) => backgroundColor};
  color: ${({ color }) => color};
  transition: background-color 0.4s linear, color 0.4s linear;
`

export const HeroSection = styled.section`
  margin-bottom: 100px;
`

export const AboutUsHeroSubtitle = styled.div`
  margin-bottom: 26px;
  letter-spacing: -0.03em;
  text-transform: uppercase;
  line-height: 1.1;
  font-weight: 700;
  color: inherit;
`

export const AboutUsHeroTitle = styled.p`
  margin-bottom: 0;
  line-height: 1.1;
  font-size: 76px;
  color: inherit;

  @media (max-width: 1024px) {
    width: 75%;
    font-size: 60px;
  }

  @media (max-width: 768px) {
    width: 90%;
    font-size: 56px;
  }
`

export const BelieveSection = styled.section`
  padding-left: 0;
  position: relative;
  color: inherit;
  margin-bottom: 100px;
`

export const BelieveSectionTitle = styled.div`
  position: absolute;
  top: 2px;
  left: 0;
  font-size: 22px;
  line-height: 1.2;
  font-weight: 700;
  max-width: 250px;
  letter-spacing: -0.04em;

  @media (max-width: 1024px) {
    position: initial;
    margin-bottom: 4px;
    max-width: 250px;
  }
`

export const BelieveSectionContent = styled.div`
  letter-spacing: -0.05em;
  text-indent: 437px;
  line-height: 1.1;
  font-size: 50px;
  font-weight: 300;

  @media (max-width: 1024px) {
    text-indent: 0;
    font-size: 40px;
  }
`

export const CardsSection = styled.section`
  position: relative;
  white-space: nowrap;
  margin-bottom: 275px;

  @media (max-width: 1024px) {
    white-space: normal;
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 0;
    row-gap: 30px;
    margin-bottom: 100px;
  }
`

export const Card = styled.div`
  flex-wrap: wrap;
  background-color: ${({ backgroundColor }) => backgroundColor};
  width: calc(20% + 48px);
  height: 390px;
  flex-direction: column;
  justify-content: space-between;
  color: #333;
  row-gap: 30px;
  transition: all 0.3s ease-in-out;
  padding: 48px 40px;
  position: relative;
  display: inline-flex;
  align-items: flex-start;

  :not(:first-child) {
    margin-left: -64px;
  }

  :first-child {
    top: 152px;
    margin-left: 0;
    color: #fff;

    & > svg {
      fill: #fff;
    }
  }

  :nth-child(2) {
    z-index: 1;
  }

  :nth-child(3) {
    z-index: 2;
    top: 260px;
  }

  :nth-child(4) {
    z-index: 3;
    top: 50px;
  }

  :nth-child(5) {
    top: 156px;
    z-index: 4;
  }

  :hover {
    z-index: 9;
  }

  @media (max-width: 1024px) {
    width: calc(50% + 17px);
    margin-right: -17px;
    height: 400px;
    row-gap: 0;

    :not(:first-child) {
      margin-left: 0;
    }

    :first-child {
      margin-top: 0;
      top: 0;
    }

    :nth-child(2) {
      left: -17px;
      top: 107px;
      margin-top: 0;
      z-index: 4;
    }

    :nth-child(3) {
      margin-top: 0;
      left: 0;
      top: 0;
    }

    :nth-child(4) {
      left: -17px;
      top: 107px;
      margin-top: 0;
      z-index: 5;
    }

    :nth-child(5) {
      left: 0;
      margin-top: 0;
      top: 0;
    }
  }

  @media (max-width: 768px) {
    padding: 14px 36px 25px 20px;
    row-gap: 24px;
    height: 229px;
  }
`

export const CardContainer = styled.div`
  max-width: 178px;
  white-space: normal;
`

export const CardImage = styled.img`
  @media (max-width: 768px) {
    max-width: 44px;
    max-height: 51px;
  }
`

export const ValueText = styled.p`
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 6px;
  line-height: 1.1;
  letter-spacing: -0.03em;
  text-transform: uppercase;

  @media (max-width: 768px) {
    font-size: 13px;
  }
`
export const ValueTitle = styled.p`
  font-size: 32px;
  font-weight: 400;
  line-height: 0.95;
  letter-spacing: -0.03em;

  @media (max-width: 768px) {
    font-size: 28px;
  }
`
export const QuestionSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  padding-top: 100px;

  & > button {
    padding: 20px 16px;
    font-size: 20px;
    width: 250px;
  }

  @media (max-width: 1024px) {
    padding-top: 0;
  }
`

export const QuestionTitle = styled.h2`
  margin: 0 0 72px;
  text-align: center;
  letter-spacing: -0.04em;
  line-height: 1;
  font-weight: 700;
  font-size: 76px;
  word-wrap: break-word;
  color: inherit;

  @media (max-width: 1024px) {
    font-size: 60px;
  }

  @media (max-width: 768px) {
    font-size: 56px;
  }
`
